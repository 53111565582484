.signup-bg-container {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-options-container {
  background-color: #ffffffe8;
  padding: 3rem 1rem;
  position: relative;
  margin: 0 5px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 40%) 0px 10px 36px 0px,
    rgba(0, 0, 0, 40%) 0px 0px 10px 1px;
}
.login-options-container .title {
  padding-bottom: 1rem;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.2rem;
  font-weight: 500;
}
.login-options-container .title p {
  margin: 4px;
}
.show-name {
  font-weight: 500;
}
.lock-icon-wrapper {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: #ff7200;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lock-icon {
  background: #fff;
  border-radius: 3px;
  width: 25px;
  height: 20px;
  margin-top: 5px;
  position: relative;
}

.lock-icon:before {
  content: "";
  display: block;
  position: absolute;
  border: 3px solid #fff;
  top: -14px;
  left: 2.3px;
  width: 15px;
  height: 27px;
  border-radius: 35px 35px 0 0;
}

.color-red {
  color: red;
}
