.tradeshow-page {
	max-width: 480px;
	padding: 1.2rem;
	margin: 0 auto;
	height: 100vh;
	height: 100svh;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.page {
	opacity: 0;
	transition: all 0.5s ease;
	position: absolute;
	inset: 0;
	bottom: 35px;
	z-index: -1;
}
.active-page {
	opacity: 1;
	transition: all 0.4s ease;
	position: relative;
	z-index: 1;
}

.fw-600 {
	font-weight: 600;
}

.nav-btns button,
.send-otp-btn,
.verify-otp-btn,
.action-btn {
	padding: 8px 30px;
	font-size: 1rem;
	border-radius: 8px !important;
	background-color: #997c62 !important;
}

.schedule-btn {
	font-size: 1.4rem;
	font-weight: 600;
	padding: 12px 36px;
	background-color: rgb(17, 112, 17) !important;
}

.form-inp-field {
	border-radius: 4px;
	position: relative;
	/* background-color: #fcfcfb; */
	background-color: #997c62;
	color: #ffffff;
	border: 1px solid #b5b5b5;
	font-size: 16px;
	line-height: 19px;
	width: 100%;
	padding: 0 12px;
	height: 48px;
	margin: 5px 0;
	outline: none;
	-webkit-text-fill-color: white !important;
}
.form-inp-field::placeholder {
	color: #ffffff;
}

.form-inp-field:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #997c62 inset;
}

.coffee-banner {
	text-align: center;
	margin-bottom: 3rem;
	/* position: absolute;
	top: 2rem;
	left: 1.2rem;
	right: 1.2rem; */
}

.coffee-banner img {
	width: 75%;
}

.raffle-text {
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 1rem;
}

.page .PhoneInputInput {
	background-color: #997c62;
	color: #ffffff;
	-webkit-text-fill-color: white !important;
}
.page .PhoneInputInput:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #997c62 inset;
}
.gap-1rem {
	gap: 1rem;
}
.gap-2rem {
	gap: 2rem;
}
.gap-3rem {
	gap: 2rem;
}
.gap-half-rem {
	gap: 0.5rem;
}

.go-back-txt {
	color: #997c62;
	text-decoration: underline;
}
.enter-otp-text {
	color: #202020;
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 18px;
}
.otp-inp::-webkit-outer-spin-button,
.otp-inp::-webkit-inner-spin-button {
	display: none;
}
.otp-inp {
	display: inline-block;
	margin: 0 auto 0.5rem;
	border: none;
	padding: 0;
	width: 6ch;
	background: repeating-linear-gradient(
			90deg,
			black 0,
			black 1ch,
			transparent 0,
			transparent 1.5ch
		)
		0 100%/ 10ch 2px no-repeat;
	font: 3rem "Ubuntu Mono", monospace !important;
	letter-spacing: 0.5ch;
}
.otp-inp:focus {
	outline: none;
	color: black;
}

.type-option {
	border: 1px solid #202020;
	padding: 4px 8px;
	border-radius: 4px;
	margin: 0;
}
.selected-type-option {
	border-color: #997c62;
	background-color: #997c62;
	color: #ffffff;
}

.thank-you-msg {
	font-weight: 600;
	font-size: 1.4rem;
	text-align: center;
}

.gift-icon {
	width: 100px;
	object-fit: contain;
}
.buy-banner {
	width: 100%;
	object-fit: contain;
	margin: 3rem 0 2rem;
}

.or-text {
	font-size: 24px;
	font-weight: 600;
	padding: 10px;
}
.or-separator {
	margin: 1rem 0;
}

.vertical-line {
	height: 2px;
	background-color: #707070;
	flex-grow: 1;
	border-radius: 10px;
}

.fs-18px {
	font-size: 18px;
}
.fs-20px {
	font-size: 20px;
}

/* MARGINS */
.mt-5px {
	margin-top: 5px;
}
.mb-2rem {
	margin-bottom: 2rem;
}

.enter-raffle-text {
	font-size: 22px;
	text-decoration: underline;
}
.glowing-card-link {
	color: #000;
	text-decoration: none;
}
.glowing-card-link:visited {
	color: #000;
}

.glowing-card {
	/* border: none;
	outline: none; */
	background: #fff;
	position: relative;
	font-size: 24px;
	font-weight: 600;
	padding: 2rem 3rem;
	border-radius: 10px;
}
.glowing-card:before {
	content: "";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}
@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
