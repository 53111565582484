.esstart-loader {
	display: flex;
	align-items: center;
	justify-content: center;
}
.esstart-loader svg {
	width: 80px;
	animation: rotateImg 3.5s linear infinite;
}
@keyframes rotateImg {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
