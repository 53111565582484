.expired_session_page {
	height: calc(100vh - 220px);
	width: 100%;
	display: flex;
	align-items: center;
	align-content: center;
	margin: auto;
	flex-wrap: wrap;
	justify-content: center;
}

.expired_session_page .error_box {
	width: 100%;
	text-align: center;
}

.expired_session_page .error_title {
	font-size: 34px;
	margin-bottom: 21px;
}

.expired_session_page button {
	margin-top: 43px;
}

.error_desc {
	font-size: 14px;
}

.session-expire-btn {
	background-color: #ff7200;
	color: #fff;
	border-radius: 1.25rem;
	font-size: 0.875rem;
	padding: 11px 43px;
	border: 0;
	outline: 0;
	cursor: pointer;
}
