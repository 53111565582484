/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
html,
body {
  font-family: 'Montserrat', sans-serif;
} */

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	background-color: #00000050;
	z-index: 1111;
	align-items: center;
	justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
