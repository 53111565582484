.alert-toast {
	max-width: 480px;
	height: 56px;
	border-radius: 0;
	border-bottom: 4px solid #ff7200;
	background: #ffe3e0;
	color: #202020;
	width: 100%;
}
.success-toast {
	background: #d4edda;
	color: #155724;
}

.pri-btn {
	background-color: rgb(255, 114, 0);
	color: rgb(255, 255, 255);
	border-radius: 20px;
	border: 0;
	outline: 0;
	cursor: pointer;
}
.flex-row {
	display: flex;
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-grow-1 {
	flex-grow: 1;
}
.align-items-center {
	align-items: center;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-between {
	justify-content: space-between;
}
.gap-1 {
	gap: 1rem;
}
.m-0 {
	margin: 0;
}
.mt-1 {
	margin-top: 1rem;
}
.mb-1 {
	margin-bottom: 1rem;
}
.footer-link {
	color: #202020;
}
.footer-link:visited {
	color: #202020;
}
.cursor-pointer {
	cursor: pointer;
}

.h-full {
	height: 100%;
}
.w-full {
	width: 100%;
}
.pos-relative {
	position: relative;
}
