h3 {
	font-size: 1.5rem;
	font-weight: 400;
}
.submit-btn {
	padding: 0.625rem 3.125rem;
	font-size: 1rem;
}
.span-txt {
	margin-bottom: 0.5rem;
}
input {
	padding: 1.031rem 0.875rem;
	font-size: 1rem;
	border-color: rgba(0, 0, 0, 0.23);
	border-radius: 3px;
	border-width: 0.8px;
}
.fs-14 {
	font-size: 14px;
}
.fs-12 {
	font-size: 12px;
}
