.welcome-screen-container {
	padding: 16px;
	height: 100svh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}
.welcome-screen-container img {
	width: 100%;
	object-fit: contain;
	flex-grow: 1;
}
.text-center {
	text-align: center;
}
.welcome-screen-container .pri-btn {
	height: 2.5rem;
	width: 15rem;
}
.welcome-screen-container h1 {
	font-weight: 500;
}
.welcome-screen__footer button {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 500;
}
.privacy_policy {
	margin: 10px 0 0;
	font-size: 12px;
}
